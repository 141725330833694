<template>
  <div class="hunt-drive" :class="{ 'selected': drive.selected }" @click="select">
    <HuntDriveTitlebar :drive="drive" :editable="editable" />

    <HuntDriveAssignmentTable v-if="drive.expanded">
      <HuntDriveAssignmentTableRow
        v-for="assignment in assignments"
        :key="assignment.id"
        :assignment="assignment"
        :drive="drive"
        :editable="editable">
      </HuntDriveAssignmentTableRow>
    </HuntDriveAssignmentTable>
  </div>
</template>

<script>
import HuntDriveTitlebar from './HuntDriveTitlebar.vue'
import HuntDriveAssignmentTable from './HuntDriveAssignmentTable.vue'
import HuntDriveAssignmentTableRow from './HuntDriveAssignmentTableRow.vue'

export default {
  components: {
    HuntDriveTitlebar,
    HuntDriveAssignmentTable,
    HuntDriveAssignmentTableRow
  },

  props: {
    drive: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      expanded: true
    }
  },

  computed: {
    assignments () {
      let assignments = this.drive.assignments.slice(0)

      if (!this.drive.showDeclinedParticipants) {
        assignments = assignments.filter(this.filterIgnoreDeclinedMembers)
      }

      return assignments.sort(this.sortByFullName)
    }
  },

  methods: {
    filterIgnoreDeclinedMembers (assignment) {
      const participants = this.$store.getters['hunt/participants/participants'] || []

      const i = participants.findIndex(participant => participant.id === assignment.memberId)
      return i !== -1 ? participants[i].status !== 'declined' : false
    },

    sortByFullName (assignmentA, assignmentB) {
      return assignmentA.getFullName().localeCompare(assignmentB.getFullName())
    },

    select () {
      this.$store.commit('hunt/drives/selectDrive', this.drive)
    }
  }
}
</script>

<style scoped>
.hunt-drive {
  padding: 1rem;
  border-radius: 4px;
  background-color: rgb(245, 245, 245);
  border: 1px solid rgb(230, 230, 230);
  margin-bottom: 2rem;
}

.hunt-drive.selected {
  border: 1px solid #808e9b;
}
</style>
