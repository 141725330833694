<template>
  <Dropdown
    v-if="editable"
    alignment="right"
    :text="role()"
    size="small">
    <DropdownItem @click="setRole('dog')">{{ $t('hunt.roles.dogHandler') }}</DropdownItem>
    <DropdownItem @click="setRole('drive')">{{ $t('hunt.roles.driveHunter') }}</DropdownItem>
    <DropdownItem @click="setRole('pass')">{{ $t('hunt.roles.standShooter') }}</DropdownItem>
    <DropdownDivider />
    <DropdownItem @click="setRole('unassigned')">{{ $t('hunt.roles.unassigned') }}</DropdownItem>
  </Dropdown>

  <span v-else>{{ role() }}</span>
</template>

<script>
export default {
  props: {
    drive: {
      type: Object,
      default: () => {}
    },

    assignment: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async setRole (role) {
      const currentRole = this.assignment.role

      try {
        this.assignment.role = role

        if (this.assignment.role !== 'pass') {
          this.assignment.markerId = null
        }

        await this.$store.dispatch('hunt/drives/updateAssignment', this.assignment)
      } catch (error) {
        this.assignment.role = currentRole
        this.$notification.danger(this.$i18n.t('general.errorOccured'))
        console.error(error)
      }
    },

    role () {
      const roles = {
        dog: this.$i18n.t('hunt.roles.dogHandler'),
        drive: this.$i18n.t('hunt.roles.driveHunter'),
        pass: this.$i18n.t('hunt.roles.standShooter'),
        unassigned: this.$i18n.t('hunt.roles.unassigned')
      }

      return this.assignment.role !== null ? roles[this.assignment.role] : this.$i18n.t('hunt.roles.unassigned')
    }
  }
}
</script>
