<template>
  <span v-if="assignment.role === 'pass'">
    <span v-if="hasMarker">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <img :src="markerIcon" />
          </div>

          <div class="level-item">
            <span class="tags has-addons push-left-xs">
              <span class="tag">{{ marker.name }}</span>
              <a v-if="editable" class="tag is-delete" @click="removeMarker"></a>
            </span>
          </div>
        </div>
      </div>
    </span>

    <Button
      v-else
      size="small"
      :title="$t('hunt.selectStand')"
      @click="selectMarker">
    </Button>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { getMarkerTypeIconUrl } from '@/api/map/markers/markers'

export default {
  props: {
    drive: {
      type: Object,
      default: () => {}
    },

    assignment: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      markers: 'hunt/map/markers'
    }),

    hasMarker () {
      return this.marker !== null
    },

    marker () {
      const markers = this.markers || []

      const i = markers.findIndex(marker => marker.id === this.assignment.markerId)
      const marker = i !== -1 ? markers[i] : null

      return marker
    },

    markerIcon () {
      return getMarkerTypeIconUrl(this.marker.type)
    }
  },

  methods: {
    selectMarker () {
      this.$store.commit('hunt/assignments/userToAssign', this.assignment)
    },

    async removeMarker () {
      const markerId = this.assignment.markerId
      this.marker.user = null
      this.assignment.markerId = null

      try {
        await this.$store.dispatch('hunt/drives/updateAssignment', this.assignment)
      } catch (error) {
        // Reset the value back to what it was before
        this.assignment.markerId = markerId
        this.marker.user = this.assignment

        this.$notification.danger(this.$i18n.t('hunt.assignments.removeMarkerFailedErrorMessage'), 5000)
        console.error(error)
      }
    }
  }
}
</script>
