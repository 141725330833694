<template>
  <div>
    <div class="level is-mobile">
      <div class="level-left is-clickable" @click="toggleExpanded">
        <div class="level-item">
          <Chevron :is-expanded="drive.expanded" />
          <h5 class="title is-5">{{ driveName }}</h5>
        </div>
      </div>

      <div class="level-right">
        <div class="level-item">
          <small>
            {{ assignments.length }} <Icon name="icon-users" />
          </small>
        </div>

        <div v-if="editable" class="level-item">
          <More alignment="right">
            <DropdownItem @click="toggleVisibleDeclinedParticipants">{{ toggeVisibleParticipantsText }}</DropdownItem>
            <DropdownDivider />
            <DropdownItem @click="remove">{{ $t('general.delete') }}</DropdownItem>
          </More>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    drive: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      subAreas: 'hunt/map/subAreas'
    }),

    subArea () {
      if (this.subAreas !== null) {
        const i = this.subAreas.findIndex(subArea => subArea.id === this.drive.subAreaId)
        return i !== -1 ? this.subAreas[i] : null
      }
      return null
    },

    driveName () {
      if (this.drive.subAreaId !== null) {
        return this.subArea !== null ? this.subArea.name : ''
      }
      return this.$i18n.t('hunt.assignments.entireHuntArea')
    },

    assignments () {
      return this.drive.showDeclinedParticipants ? this.drive.assignments : this.drive.assignments.filter(this.filterIgnoreDeclinedMembers)
    },

    toggeVisibleParticipantsText () {
      return this.drive.showDeclinedParticipants ? this.$i18n.t('hunt.assignments.hideDeclinedParticipants') : this.$i18n.t('hunt.assignments.showAllParticipants')
    }
  },

  methods: {
    filterIgnoreDeclinedMembers (assignment) {
      const participants = this.$store.getters['hunt/participants/participants'] || []

      const i = participants.findIndex(participant => participant.id === assignment.memberId)
      return i !== -1 ? participants[i].status !== 'declined' : false
    },

    toggleExpanded (event) {
      this.drive.expanded = !this.drive.expanded
    },

    toggleVisibleDeclinedParticipants () {
      this.drive.showDeclinedParticipants = !this.drive.showDeclinedParticipants
    },

    async remove () {
      const i18n = this.$i18n

      try {
        const response = await this.$dialog.confirm({
          title: this.$t('general.delete'),
          message: this.$t('hunt.assignments.removeDrive', {
            name: this.subArea !== null ? this.subArea.name : this.$t('hunt.assignments.entireHuntArea')
          }),
          ok: this.$t('general.delete')
        })

        if (response.ok) {
          await this.$store.dispatch('hunt/drives/removeDrive', this.drive)
        }
      } catch (error) {
        // WTF!? For some really weird reason this.$i18n gets set to null when the call to the store throws an exception.
        // Because of this we have to make a copy and use the local variable instead.
        this.$notification.danger(i18n.t('hunt.assignments.removeDriveErrorMessage'))
        console.error(error)
      }
    }
  }
}
</script>

<style scoped>
  .level {
    margin-bottom: 0;
  }
</style>
