<template>
  <div>
    <InlineLoader v-if="isLoading" />

    <div v-else>
      <p v-if="canEdit">
        {{ $t('hunt.assignments.helpText') }}
      </p>

      <p v-else-if="!canEdit && drives.length === 0" class="has-text-grey">
        {{ $t('hunt.assignments.noAssignments') }}
      </p>

      <SubAreaPicker v-if="canEdit" />

      <HuntDrive
        v-for="drive in drives"
        :key="drive.id"
        :drive="drive"
        :editable="canEdit">
      </HuntDrive>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import HuntDrive from './HuntDrive.vue'
import SubAreaPicker from './SubAreaPicker.vue'

export default {
  components: {
    HuntDrive,
    SubAreaPicker
  },

  data () {
    return {
      selectedArea: ''
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      canEdit: 'hunt/canEdit',
      drives: 'hunt/drives/getAll'
    }),

    isLoading () {
      return this.drives === null
    }
  },

  destroyed () {
    this.$store.commit('hunt/drives/selectDrive', null)
  }
}
</script>
