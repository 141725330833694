<template>
  <tr>
    <td>
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <ProfilePicture :userId="Number(assignment.userId)" size="small" round />
          </div>

          <div class="level-item">
            <div>
              <p class="member-name" :class="[memberClass]">
                {{ assignment.getFullName() }}
              </p>

              <span v-if="isHuntLeader || isTrackingHunter" class="tags">
                <span v-if="isHuntLeader" class="tag is-link is-light">{{ $t('hunt.huntLeader.title') }}</span>
                <span v-if="isTrackingHunter" class="tag is-link is-light">{{ $t('hunt.trackingHunter.title') }}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </td>

    <td>
      <HuntAssignmentRoleDropdown
        :drive="drive"
        :assignment="assignment"
        :editable="editable">
      </HuntAssignmentRoleDropdown>
    </td>

    <td>
      <HuntAssignmentMarkerButton
        :drive="drive"
        :assignment="assignment"
        :editable="editable">
      </HuntAssignmentMarkerButton>
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'
import HuntAssignmentRoleDropdown from './HuntAssignmentRoleDropdown.vue'
import HuntAssignmentMarkerButton from './HuntAssignmentMarkerButton.vue'

export default {
  components: {
    HuntAssignmentRoleDropdown,
    HuntAssignmentMarkerButton
  },

  props: {
    assignment: {
      type: Object,
      default: () => {}
    },

    drive: {
      type: Object,
      default: () => {}
    },

    editable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt'
    }),

    isHuntLeader () {
      return this.assignment.userId === this.hunt.huntLeaderUserId
    },

    isTrackingHunter () {
      return this.assignment.userId === this.hunt.afterSearchUserId
    },

    hasDeclined () {
      const participants = this.$store.getters['hunt/participants/participants'] || []
      const i = participants.findIndex(participant => participant.id === this.assignment.memberId)
      return i !== -1 ? participants[i].status === 'declined' : false
    },

    memberClass () {
      return this.hasDeclined ? 'declined' : ''
    }
  }
}
</script>

<style scoped>
table td {
  background-color: white;
  border-color: white;
  padding: 10px;
}

table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

table td:last-child {
  border-right-style: solid;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.member-name {
  margin-bottom: 0;
}

.member-name.declined {
  text-decoration: line-through;
  opacity: 0.5;
}
</style>
