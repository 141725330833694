<template>
  <div class="select push-bottom" :class="{ 'is-loading': loading }">
    <select
      v-model="selectedArea"
      class="select"
      :class="{ 'is-disabled': loading }"
      @change="newDrive">
      <option disabled value="">{{ $t('hunt.assignments.addArea') }}</option>
      <option v-for="subArea in availableSubAreas" :key="subArea.id" :value="subArea">{{ subArea.name }}</option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedArea: '',
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      hunt: 'hunt/hunt',
      boundary: 'hunt/map/boundary',
      subAreas: 'hunt/map/subAreas',
      drives: 'hunt/drives/getAll'
    }),

    availableSubAreas () {
      let subAreas = []

      if (this.subAreas) {
        subAreas = subAreas.concat(this.subAreas)
      }

      if (this.drives) {
        this.drives.forEach(drive => {
          for (let i = 0; i < subAreas.length; i++) {
            if (drive.subAreaId !== null) { // Is subarea
              if (drive.subAreaId === subAreas[i].id) {
                subAreas.splice(i, 1)
                break
              }
            } else { // Is area
              if (subAreas[i].id === 0) {
                subAreas.splice(i, 1)
                break
              }
            }
          }
        })
      }

      subAreas.sort((a, b) => a.name.localeCompare(b.name))

      if (this.boundary && this.boundary.coordinates.length > 0) {
        subAreas.unshift({ id: 0, name: this.$i18n.t('hunt.assignments.entireHuntArea') })
      }
      return subAreas
    }
  },

  methods: {
    async newDrive () {
      if (this.selectedArea !== '') {
        this.loading = true

        try {
          await this.$store.dispatch('hunt/drives/createDriveForSubArea', this.selectedArea.id > 0 ? this.selectedArea.id : null)
        } catch (error) {
          this.$notification.danger(this.$i18n.t('hunt.assignments.createDriveFailedErrorMessage'))
        }

        this.loading = false
        this.selectedArea = ''
        /*
        // Check if whole area is selected
        if (this.selectedArea.id === 0) {
          this.create(null, null)
        } else {
          this.create(this.selectedArea.name, this.selectedArea)
        }

        this.selectedArea = ''
        */
      }
    }
  }
}
</script>
