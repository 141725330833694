<template>
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>{{ $t('hunt.participant') }}</th>
        <th>{{ $t('general.role') }}</th>
        <th>{{ $t('hunt.assignments.stand') }}</th>
      </tr>
    </thead>

    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script>
export default {
}
</script>

<style scoped>
.table {
  background-color: rgb(245, 245, 245);
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table thead {
  font-size: 0.8rem;
}

.table thead th {
  border-bottom: none;
  color: lightslategrey;
}
</style>
